var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("blocks-drop-down", {
    attrs: { "has-close": false, "has-icon": "" },
    scopedSlots: _vm._u([
      {
        key: "activatorBtnText",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "titleMedium--text mew-body activator-btn-text d-flex",
              },
              [_vm._v(" " + _vm._s(_vm.selectedSort.text) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "cardContent",
        fn: function () {
          return _vm._l(_vm.sort, function (item) {
            return _c(
              "v-list",
              { key: item.value, staticClass: "py-0" },
              [
                _c(
                  "v-list-item",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.setSelected(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-title",
                      {
                        staticClass:
                          "mew-body basic--text subItem d-flex align-center pt-",
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          })
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }